
import { Component, Vue } from "vue-property-decorator";
import { FormItemInfo } from "../interface/form";
import CustomForm from "./CustomForm.vue";
import { tsService } from "@/shared/services/api/trouble-shooting";
import { from, Observable } from "rxjs";
import { GET_ALL_JOB_PARAMS, PRODUCT_NAME } from "../interface/newItem";
import { SEARCH_ITEM_CRITERIA } from "../interface/searchCriteria";
@Component({
  name: "SearchItem",
  components: {
    CustomForm,
  },
})
export default class SearchItem extends Vue {
  searchFormArray: FormItemInfo[] = [
    {
      label: "",
      name: "ownerType",
      value: "My",
      inputType: "radio",
      options: [
        { id: 1, value: "My" },
        { id: 2, value: "All" },
      ],
    },
    {
      label: "User ID",
      name: "userID",
      value: "",
      inputType: "text",
    },
    {
      label: "Runbook Name",
      name: "runbookId",
      value: "",
      inputType: "select",
      selectMultiple: true,
      options: [],
    },
    {
      label: "Summary",
      name: "summary",
      value: "",
      inputType: "text",
    },
    {
      label: "Created time range",
      name: "createdTimeRange",
      value: "",
      inputType: "daterange",
    },
    {
      label: "Only Show Work Items with Reports",
      name: "workitemReports",
      value: "",
      inputType: "checkbox",
    },
  ];

  jobsObs$: Observable<any>;
  ownerType = "My";

  created(): void {
    const getAllJobParams: GET_ALL_JOB_PARAMS = {
      rundeckName: [PRODUCT_NAME.ccv2, PRODUCT_NAME.scv2],
    };
    this.jobsObs$ = from(tsService.getAllJobs(getAllJobParams));
    let runbookItem: FormItemInfo = this.searchFormArray.filter(
      (item) => item.name === "runbookId"
    )[0];

    const userID = this.searchFormArray.filter(
      (item) => item.name === "userID"
    )[0];
    userID.value = this.$store.state.userId;

    this.jobsObs$.subscribe((res) => {
      const response = res.data.data;
      response.forEach((product) => {
        product?.jobs.forEach((workitem: any) => {
          if (runbookItem && runbookItem.options)
            runbookItem?.options.push({
              id: workitem.id,
              value: workitem.jobName,
            });
        });
      });
      runbookItem?.options?.sort((a, b) =>
        a.value?.toLowerCase() > b.value?.toLowerCase() ? 1 : -1
      );
    });
  }

  updateFormArray(updatedForm: FormItemInfo[]): void {
    const workitem = updatedForm.filter((item) => item.name === "ownerType");
    const userID = this.searchFormArray.filter(
      (item) => item.name === "userID"
    );
    if (workitem[0].value === "My") {
      userID[0].value = this.$store.state.userId;
      this.ownerType = workitem[0].value;
    } else if (workitem[0].value !== this.ownerType) {
      //initialize empty string to userID once when option is changed from My->All
      userID[0].value = "";
      this.ownerType = workitem[0].value;
    }
  }

  emitSearchCriteria(formData: SEARCH_ITEM_CRITERIA): void {
    this.$emit("onSearch", formData);
  }
}
